<template>
  <span
    class="flex items-center p-4 hover:bg-indigo-500 hover:text-white border-gray-200 border-b cursor-pointer"
    @click="handleClick"
    ><span class="mr-2">
      <svg
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        class="w-6 h-6"
      >
        <path :d="menu.icon"></path>
      </svg>
    </span>
    <span>{{ menu.nombre }}</span></span
  >
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", this.menu.path);
    }
  }
});
</script>
