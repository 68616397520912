import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PublicLayout from "../layouts/PublicLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";

const routes: Array<RouteRecordRaw> = [
  /*  {
    path: "/",
    name: "Home",
    component: Home
  },*/
  {
    path: "/",
    component: PublicLayout,
    redirect: "login",
    children: [
      {
        path: "/login/:nextUrl?",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Login.vue"),
      },
    ],
  },
  {
    path: "/app",
    component: AppLayout,
    redirect: "/app/agenda",
    meta: {
      title: "App",
      requiresAuth: true,
    },
    children: [
      {
        path: "usuarios",
        name: "usuarios",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/usuarios/Usuarios.vue"
          ),
        meta: {
          admin: true,
        },
      },
      {
        path: "agenda",
        name: "agenda",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/agenda/Agenda.vue"),
      },
      {
        path: "clientes/:id?",
        name: "clientes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/clientes/Clientes.vue"
          ),
      },
      {
        path: "fabricantes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/fabricantes/Fabricantes.vue"
          ),
      },
      {
        path: "pedidos/:idCliente?/:numeroPedido?",
        name: "pedidos",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/pedidos/Pedidos.vue"
          ),
      },
      {
        path: "tiendas/:idCliente?",
        name: "tiendas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/tiendas/Tiendas.vue"
          ),
      },
      {
        path: "expediciones/:idPedido?",
        name: "expediciones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/expediciones/Expediciones.vue"
          ),
      },
      {
        path: "comisiones",
        name: "comisiones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/comisiones/Comisiones.vue"
          ),
      },
      {
        path: "facturas/:idFactura?/:Editar?/:idReceptor?",
        name: "facturas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/facturas/Facturas.vue"
          ),
      },
      {
        path: "colecciones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/colecciones/Colecciones.vue"
          ),
      },
      {
        path: "temporadas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/temporadas/ConfigTemporadas.vue"
          ),
      },
      {
        path: "transportistas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/transportistas/Transportistas.vue"
          ),
      },
      {
        path: "poblaciones",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/poblaciones/Poblaciones.vue"
          ),
      },
      {
        path: "configuracionfacturas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/configfacturas/ConfigFacturas.vue"
          ),
      },
      {
        path: "receptoresfactura",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/receptoresfactura/ReceptoresFactura.vue"
          ),
      },
    ],
  },
  //REPORTS
  {
    path: "/Clientes/ClientesConColecciones/:years",
    name: "ClientesConColecciones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ClientesConColecciones.vue"
      ),
  },
  {
    path: "/Clientes/ClientesPorProvincia/:observaciones",
    name: "ClientesPorProvincia",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ClientesPorProvincia.vue"
      ),
    props: true,
  },
  {
    path: "/Clientes/ClientesPorMarca",
    name: "ClientesPorMarca",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ClientesPorMarca.vue"
      ),
  },
  {
    path: "/Clientes/ClientesEmail",
    name: "ClientesEmail",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ClientesEmail.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosFacturasPendientes",
    name: "PedidosFacturasPendientes",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosFacturasPendientes.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosConfirmadosVsServidos/:detail",
    name: "PedidosConfirmadosVsServidos",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosConfirmadosVsServidos.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosVendidoVsConfirmado",
    name: "PedidosVendidoVsConfirmado",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosVendidoVsConfirmado.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosVendidoVsConfirmado/:detail",
    name: "PedidosVendidoVsConfirmado",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosVendidoVsConfirmado.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosPrevisionComisiones/",
    name: "PedidosPrevisionComisiones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosPrevisionComisiones.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosPorColecciones",
    name: "PedidosPorColecciones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosPorColecciones.vue"
      ),
  },
  {
    path: "/Pedidos/PedidosBudget/:marca?",
    name: "PedidosBudget",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/PedidosBudget.vue"
      ),
  },
  {
    path: "/Facturas/FacturaPrint/:factura?",
    name: "FacturaPrint",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/FacturaPrint.vue"
      ),
  },
  {
    path: "/Expediciones/ExpedicionesPrevisionComisiones",
    name: "ExpedicionesPrevisionComisiones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ExpedicionesPrevisionComisiones.vue"
      ),
  },
  {
    path: "/Expediciones/ExpedicionesEstadoComisiones",
    name: "ExpedicionesEstadoComisiones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ExpedicionesEstadoComisiones.vue"
      ),
  },
  {
    path: "/Comisiones/ComisionesComisiones",
    name: "ComisionesComisiones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/ComisionesComisiones.vue"
      ),
  },
  {
    path: "/Facturas/FacturasPorReceptor",
    name: "FacturasPorReceptor",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/FacturasPorReceptor.vue"
      ),
  },
  {
    path: "/Facturas/FacturasFacturas",
    name: "FacturasFacturas",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/FacturasFacturas.vue"
      ),
  },
  {
    path: "/Agenda/AgendaCitasConColecciones/:detail/:years?",
    name: "AgendaCitasConColecciones",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/AgendaCitasConColecciones.vue"
      ),
  },
  {
    path: "/Fabricantes/FabricantesFabricantes",
    name: "FabricantesFabricantes",
    component: () =>
      import(
        /* webpackChunkName: "listado2" */ "../views/reports/FabricantesFabricantes.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.admin)) {
    if (localStorage.getItem("admin") == undefined) {
      next({
        path: `/login`,
      });
      return;
    } else {
      if (localStorage.getItem("token") == undefined) {
        next({
          path: `/login`,
        });
        return;
      }
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == undefined) {
      const newPath = to.fullPath.replace("/app", "");
      next({
        path: `/login${newPath}`,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
