
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", this.menu.path);
    }
  }
});
