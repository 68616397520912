<template>
  <navbar />
  <div class="bg-gray-200 min-h-screen">
    <div class="container mx-auto">
      <router-view></router-view>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import navbar from "@/components/navbar.vue";
export default defineComponent({
  name: "AppLayout",
  components: {
    navbar
  },
  setup() {
    return {};
  }
});
</script>
