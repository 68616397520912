
import { defineComponent } from "vue";
import navbar from "@/components/navbar.vue";
export default defineComponent({
  name: "AppLayout",
  components: {
    navbar
  },
  setup() {
    return {};
  }
});
